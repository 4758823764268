<template>
  <section>
    <v-layout class="mx-3 mt-3 pb-4">
      <v-flex>
        <products-list-table :loading="loading" :products="products" :pagination="pagination" @searched="search"
                             @update:options="updateOptions"
        />
        <product-edit-modal v-if="productEdit && !addNew" :product-id="productEdit" />
        <product-edit-modal v-else-if="addNew" :product-id="0" mode="new" @created="created" />
      </v-flex>
    </v-layout>
  </section>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import ProductsListTable from '@/components/Catalog/Products/ProductsListTable'
import ProductEditModal from '@/components/Catalog/ProductEditModal'

export default {
  name: 'ProductsList',
  components: {
    ProductsListTable,
    ProductEditModal
  },
  data () {
    return {
      productEdit: false,
      addNew: false,
      loading: false,
      products: [],
      pagination: {
        total: 0,
        options: {
          itemsPerPage: 10,
          page: 1
        }
      },
      searchTimeout: null
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/getAccessToken'
    })
  },
  methods: {
    ...mapActions({
      getProducts: 'catalog/product/getProducts'
    }),
    created () {
      this.updateOptions(this.pagination.options)
    },
    updateProductData({ product_id, product }) {
      let idx = this.products.findIndex(pr => pr.entity_id === product_id)
      if (idx === false) {
        return
      }
      this.products.splice(idx, 1, product)
    },
    updateProductEditModal(productId) {
      this.productEdit = productId
    },
    updateAddNewModal (value) {
      this.productEdit = false
      this.addNew = value
    },
    updateOptions (value) {
      this.loading = true
      const params = {
        ipp: value.itemsPerPage,
        page: value.page,
      };
      if (value.search) {
        params.search = value.search;
      }
      this.getProducts({ token: this.token, params })
        .then(result => {
          this.products = result.data.data
          this.pagination.total = result.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    search (value) {
      this.searchTimeout = setTimeout(() => {
        this.processSearch(value)
        clearTimeout(this.searchTimeout)
      }, 500)
    },
    processSearch (value) {
      this.loading = true
      this.getProducts({ token: this.token, params: {
        ipp: this.pagination.options.itemsPerPage, page: 1, search: value
      } })
        .then(result => {
          this.products = result.data.data
          this.pagination.total = result.data.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    async updateProductsList () {
      await this.getProducts({ token: this.token, params: {
          ipp: this.pagination.options.itemsPerPage, page: 1
        } })
        .then(result => {
          this.products = result.data.data
          this.pagination.total = result.data.total
        })
    }
  },
  mounted () {
    this.loading = true
    this.getProducts({ token: this.token, params: {
      ipp: this.pagination.options.itemsPerPage, page: this.pagination.options.page
    } })
      .then(result => {
        this.products = result.data.data
      })
      .finally(() => {
        this.loading = false
      })
    this.$bus.$on('catalog-product-update', this.updateProductData)
    this.$bus.$on('catalog-products-update-list', this.updateProductsList)
    this.$bus.$on('catalog-product-edit', this.updateProductEditModal)
    this.$bus.$on('catalog-product-new', this.updateAddNewModal)
    this.$bus.$on('catalog-product-loader', (loading) => this.loading = loading)
  },
  beforeDestroy () {
    this.$bus.$off('catalog-product-update', this.updateProductData)
    this.$bus.$off('catalog-products-update-list', this.updateProductsList)
    this.$bus.$off('catalog-product-edit', this.updateProductEditModal)
    this.$bus.$off('catalog-product-new', this.updateAddNewModal)
    this.$bus.$off('catalog-product-loader', (loading) => this.loading = loading)
  }
}
</script>
